.circular-image-small {
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.no-profile-image {
  width: 36px;
  height: 36px;
  background: #512DA8;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin: 20px 0;
}

