.overflowHidden {
  text-transform: none !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.formControl {
  width:100%;
  margin-right: 20px !important;
}

.select {
  margin-right: 12px;
  height: 35px;
  & .MuiSelect-select:focus {
    background-color: transparent
  }
}
