.rbc-event.rbc-selected {
  //background-color: $primary !important;
}

.rbc-event:focus-within {
  // border: 2px solid #53c9fc !important;
}

.rbc-calendar {
  height: auto;
  flex-grow: 1;
}
.rbc-header {
  padding: 12px 16px !important;
  a {
    padding-bottom: 8px !important;
  }
  span {
    font-size: 15px !important;
    font-weight: 500;
  }
}
.calendar-header {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.eventFlowFocused {
  &.Mui-focused {
    border: 2px solid #941bcc;
    border-bottom: 0;
    box-shadow: 0 0 5px #941bcc;
    &.MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
  &.MuiInput-underline.Mui-error:after {
    border-bottom-color: #941bcc !important;
  }
}

.loaderContainer {
  width:100%;
  height:calc(100% - 64px);
  top:64px;
  margin: 0;
  background: rgba(0, 0, 0, 0.3);
  display:block;
  position: absolute;
  z-index: 2000;
}

.loader {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

.tooltip {
  position: relative;
}

.tooltip .tooltipText {
  visibility: visible;
  width: 120px;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  color: #941bcc;
  font-size: 13px;
  background-color: #fff;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.06), 0px 5px 8px 0px rgba(0, 0, 0, 0.04), 0px 1px 14px 0px rgba(0, 0, 0, 0.04);

  position: absolute;
  z-index: 1;
  bottom: 80%;
  left: 50%;
  margin-left: -60px;
}

.calendar_loader {
  padding-left: calc(50% - 50px);
  padding-top: 20px;
  padding-bottom: 20px;
}
