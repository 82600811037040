.signup {
  background: #373a49;
  

  .signup-card {
    max-width: 950px;
    border-radius: 12px !important;
    img {
      width: 300px;
    }
  }
}
